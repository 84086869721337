/* eslint-disable */

<template>
  <div id="Lgx315088047538438144">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-4">
            <h3 class="font-bold mb-4">Snippets</h3>
            <vue-draggable-container id="wordBank" class="list wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer command-block">192.168.1.1</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">192.168.1.2</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">65000</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">65000</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">65001</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">65001</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">Client</h5>
              <h5 class="dragBlock font-semibold cursor-pointer command-block">ISP</h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="pt-0">
              <div class="mt-base">
                <h3>
                  &lt;config xmins:xc “urn:ietf:params:xml:ns:netconff:base:1.0” xmins “urn:ietf:params :xml:ns:netconfig:base:1.0”&gt;
                </h3>
                <h3>
                  &nbsp;&nbsp;&lt;native xmins http://cisco.com/ns/yang/Cisco-IOS-XE-native xmins : ios-bgp
                  http://cisco.com/ns/yang/Cisco-IOS-SE-bgp&gt;
                </h3>
                <h3>&nbsp;&nbsp;&nbsp;&nbsp;&lt;router&gt;</h3>
                <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp: bgp&gt;</h3>

                <div class="flex items-center my-3">
                  <h3 class="mr-8">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp: id&gt;</h3>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input0"
                    style="width: 10vw"
                  ></vue-draggable-container>
                  <h3 class="ml-8">&lt;/ios-bgp:id&gt;</h3>
                </div>

                <h3>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:neighbor></h3>

                <div class="flex items-center my-3">
                  <h3 class="mr-8">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp: id&gt;</h3>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input1"
                    style="width: 10vw"
                  ></vue-draggable-container>
                  <h3 class="ml-8">&lt;/ios-bgp:id&gt;</h3>
                </div>

                <div class="flex items-center my-3">
                  <h3 class="mr-8">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios.bgp:remote-as&gt;</h3>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input2"
                    style="width: 10vw"
                  ></vue-draggable-container>
                  <h3 class="ml-8">&lt;/ios-bgp:remote-as&gt;</h3>
                </div>

                <h3>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:neighbor><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:address-family><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:no-vrf><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp: ipv4><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:af-name>unicast&lt;/ios-bgp:af-name><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:ipv4-unicast><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:
                  neighbor><br />
                </h3>

                <div class="flex items-center my-3">
                  <h3 class="mr-8">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios-bgp:
                    id&gt;
                  </h3>
                  <vue-draggable-container
                    class="answerStandard dropzone dropzoneStandard answer-bg"
                    id="input3"
                    style="width: 10vw"
                  ></vue-draggable-container>
                  <h3 class="ml-8">&lt;/ios-bgp: id&gt;</h3>
                </div>

                <h3>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;ios bgp:
                  soft-reconfiguration>inbound&lt;/ios-bgp:soft-reconfiguration><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:neighbor><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:ipv4-unicast><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:ipv4-ipv4><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:no-vrf><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:address-family><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/ios-bgp:bgp><br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&lt;/iosrouter><br />
                  &nbsp;&nbsp;&lt;/native><br />
                  &lt;/config><br />
                </h3>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard-command';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          //this.drag_error()
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === 'ISP') {
        totalScore++;
        document.getElementById('input0').style.borderColor = '#77FAAE';
        document.getElementById('input0').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input1').textContent === '192.168.1.1') {
        totalScore++;
        document.getElementById('input1').style.borderColor = '#77FAAE';
        document.getElementById('input1').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input2').textContent === '65001') {
        totalScore++;
        document.getElementById('input2').style.borderColor = '#77FAAE';
        document.getElementById('input2').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }
      if (document.getElementById('input3').textContent === '65001') {
        totalScore++;
        document.getElementById('input3').style.borderColor = '#77FAAE';
        document.getElementById('input3').style.backgroundColor = '#77FAAE';
      } else {
        document.getElementById('input3').style.borderColor = '#fd4445';
        document.getElementById('input3').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#Lgx315088047538438144 {
  .answerStandard {
    min-height: 55px;
  }
}

.currently-dragging-standard-command {
  background-color: #0042ff !important;
  padding: 12px;
  color: white;
  z-index: 99;
  min-width: auto;
}

.command-block {
  display: inline-flex;
  max-height: 100px;
  margin: 5px;
  padding: 10px;
  text-align: left;
  color: #fff;
  border: 1px solid #3f3f3f;
  background-color: #fd4445;
  border-radius: 5px;
}
</style>
